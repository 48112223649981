import React, { useEffect, useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { SwitchTransition, Transition } from 'react-transition-group'

import Header from './Header'
import Footer from './Footer'
import styled from 'styled-components'

import { useInterval } from 'react-use'
import { useStore } from '../store/useStore'


import '../root.scss'

const TRANSITION_DURATION = 400

const TRANSITION_STYLES = {
	default: {
		transition: `opacity ${TRANSITION_DURATION}ms ease`,
	},
	entering: {
		opacity: 0,
	},
	entered: {
		opacity: 1,
	},
	exiting: {
		opacity: 0,
	},
	exited: {
		opacity: 0,
	},
}

const LayoutStyle = styled.main`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: -1;
	transition: background-color 2.5s ease, opacity 400ms ease;
`

const Button = styled.button`
	padding: 0;
	text-transform: uppercase;
	position: fixed;
	top: 49%;
	background: transparent;
	border: 0;
	font-size: 1em;
	cursor: pointer;

	&:focus {
		outline: 1px solid transparent;
	}
`

const LeftButton = styled(Button)`
	left: 20px;
`
const RightButton = styled(Button)`
	right: 20px;
`

const Wrapper = ({ children, home, path }) => {
	const data = useStaticQuery(graphql`
		{
			allSanitySiteSettings {
				nodes {
					colorOrder {
						title
						id
						code
						_rawBody
					}
				}
			}
		}
	`)

	const { hydrateColors, colors = [], currentColor, setCurrentColor, interval, toggleInterval } = useStore(s => ({
		hydrateColors: s.hydrateColors,
		colors: s.colors,
		currentColor: s.currentColor,
		setCurrentColor: s.setCurrentColor,
		interval: s.interval,
		toggleInterval: s.toggleInterval,
	}))

	const nextColor = () => {
		let currentIndex = colors.indexOf(currentColor)

		if (currentIndex + 1 > colors.length - 1) {
			currentIndex = -1
		}

		setCurrentColor(currentIndex + 1)
	}

	const prevColor = () => {
		let index = colors.indexOf(currentColor)

		if (index - 1 < 0) {
			index = colors.length
		}

		setCurrentColor(index - 1)
	}

	useEffect(() => {
		if (colors.length === 0) {
			hydrateColors(data.allSanitySiteSettings.nodes[0].colorOrder, data.allSanitySiteSettings.nodes[0].colorOrder[0])
		}
	}, [colors])

	useEffect(() => {
		const body = document.body
		body.style.backgroundColor = currentColor.code
	}, [currentColor])

	useEffect(() => {
		const body = document.body

		interval ? body.classList.add('autoplay') : body.classList.remove('autoplay')
		return () => {
			body.classList.remove('autoplay')
		}
	}, [interval])

	useInterval(
		() => {
			nextColor()
		},
		interval ? 6000 : null,
	)

	return (
		<React.Fragment>
			<Header home={home} />
			<SwitchTransition>
				<Transition key={path} mountOnEnter unmountOnExit appear timeout={TRANSITION_DURATION}>
					{status => (
						<LayoutStyle
							style={{
								...TRANSITION_STYLES.default,
								...TRANSITION_STYLES[status],
							}}>
							{children}
							{home && (
								<React.Fragment>
									<LeftButton onClick={prevColor}>Previous </LeftButton>
									<RightButton onClick={nextColor}>Next </RightButton>

									<Footer
										color={{
											title: currentColor.title,
											body: currentColor._rawBody,
										}}
									/>
								</React.Fragment>
							)}
						</LayoutStyle>
					)}
				</Transition>
			</SwitchTransition>
		</React.Fragment>
	)
}

export default Wrapper
