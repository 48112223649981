import create from 'zustand'

import { mountStoreDevtool } from 'simple-zustand-devtools'

export const getInitialState = (set, get) => ({
	// UI
	colors: [],
	currentColor: {
		title: 'Violet',
		code: 'hsl(300, 76%, 72%)',
		_rawBody: [
			{
				_key: '4d1c46e5f96f',
				_type: 'block',
				children: [
					{
						_key: '4d1c46e5f96f0',
						_type: 'span',
						marks: [],
						text: 'Violet stimulates Intuition, Imagination, Self-Knowledge, Meditation, Artistic Qualities',
					},
				],
				markDefs: [],
				style: 'normal',
			},
		],
	},
	interval: false,
})

export const hydrateColors = set => async (colors, currentColor) => {
	set({ colors, currentColor })
}

export const setColors = set => colors => {
	set({ colors })
}

export const setCurrentColor = (set, get) => index => {
	const { colors } = get()
	set({ currentColor: colors[index] })
}
export const toggleInterval = (set, get) => () => {
	const { interval } = get()
	set({ interval: !interval })
}

export const useStore = create((...args) => ({
	// State
	...getInitialState(...args),

	// Actions
	hydrateColors: hydrateColors(...args),
	setColors: setColors(...args),
	setCurrentColor: setCurrentColor(...args),
	toggleInterval: toggleInterval(...args),
}))

if (process.env.NODE_ENV === 'development') {
	mountStoreDevtool('Luz Mistica', useStore)
}
