import React, { useState, useEffect } from "react";
import BlockContent from "@sanity/block-content-to-react";
import styled from "styled-components";
import { Link } from "gatsby";
import Arrow from "../images/arrow.svg";

import { useStore } from '../store/useStore'
import cx from 'classnames'

const FooterWrapper = styled.footer`
	position: fixed;
	left: 20px;
	bottom: 20px;
	right: 20px;

	.color-info {
		width: 50%;

		@media (min-width: 1024px) {
			max-width: 55ch;
			width: 100%;
		}
	}

	h2 {
		text-transform: uppercase;
		margin-bottom: 20px;
	}
`

const FullScreenButton = styled.button`
	position: absolute;
	right: 0px;
	bottom: 0px;
	background-color: transparent;
	font-size: 1em;
	border: 1px solid black;
	text-transform: uppercase;
	font-family: 'SuisseIntlRegular', 'Helvetica Neue', Helvetica, sans-serif;
	cursor: pointer;
	padding: 5px 15px;
	border-radius: 50px;
	transition: background-color 300ms ease, width 300ms ease;

	&.on {
		background: black;
		color: white;
	}

	&:focus {
		outline: 1px solid transparent;
	}

	a {
		color: inherit;
		text-decoration: none;
	}

	img {
		max-height: 1.5rem;
		height: 1.5rem;
		vertical-align: bottom;
		width: auto;
		display: inline;
	}
`

const Footer = ({ color }) => {
	const [displayButton, setDisplayButton] = useState(false)

	useEffect(() => {
		const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
		iOS ? setDisplayButton(false) : setDisplayButton(true)
	}, [])

	const { interval, toggleInterval } = useStore(s => ({
		interval: s.interval,
		toggleInterval: s.toggleInterval,
	}))

	return (
		<FooterWrapper>
			{color.title && <h2>{color.title}</h2>}
			{color.body && (
				<div className='color-info'>
					<BlockContent blocks={color.body} />
				</div>
			)}

			<FullScreenButton
				className={cx('', {
					on: interval,
				})}
				onClick={toggleInterval}>
				{interval ? 'Autoplay On' : 'Autoplay Off'}
			</FullScreenButton>
		</FooterWrapper>
	)
}

export default Footer;
